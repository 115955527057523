// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-slapstick-fighter-js": () => import("./../../../src/pages/slapstick-fighter.js" /* webpackChunkName: "component---src-pages-slapstick-fighter-js" */),
  "component---src-pages-slapstick-fighter-presskit-js": () => import("./../../../src/pages/slapstick-fighter/presskit.js" /* webpackChunkName: "component---src-pages-slapstick-fighter-presskit-js" */),
  "component---src-pages-subscribe-confirmed-js": () => import("./../../../src/pages/subscribe-confirmed.js" /* webpackChunkName: "component---src-pages-subscribe-confirmed-js" */),
  "component---src-pages-subscribe-request-js": () => import("./../../../src/pages/subscribe-request.js" /* webpackChunkName: "component---src-pages-subscribe-request-js" */)
}

