/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

const WOW = require("wowjs")


// You can delete this file if you're not using it
exports.onInitialClientRender = () => {
    
    window.WOW = new WOW.WOW().init();

}
